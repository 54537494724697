<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" center
               append-to-body :close-on-click-modal="false" width="1000">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="类型名称" prop="name" class="w50">
                <el-input v-model="form.name" size="small" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="类型编码" prop="code" class="w50">
                <el-input v-model="form.code" size="small" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="排序号" prop="sort" class="w50">
                <el-input-number v-model.number="form.sort" size="small" :min=0 :max=10000 :step=1></el-input-number>
            </el-form-item>
            <el-form-item label="是否固化" prop="status" class="w50">
                <x-radio-group v-model="form.status" size="small" dictType="T_YES_OR_NO"/>
            </el-form-item>
            <el-form-item label="状态" prop="use" class="w50">
                <x-radio-group v-model="form.use" size="small" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="描述" prop="description" class="w50">
                <el-input type="textarea" size="small" v-model="form.description" maxlength="70"
                          show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add" :loading="loading">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import XEditBase from "@/components/x/XEditBase";
import {ruleBuilder} from '@/util/validate';
import * as service from "@/service/system/dict";

export default {
    name: "DictEdit",
    mixins: [XEditBase],
    beforeCreate() {
        let {required} = ruleBuilder;
        this.rules = {
            name: [required()], // 字典名称
            code: [required()], // 字典编码
        }
        this.titlePrefix = "字典信息";
        this.defaultForm = {
            id: null,
            sort: 0,
            description: null,
            code: null,
            pcode: '0',
            name: null,
            status: "false",
            use: "false"
        }
        this.addService = service.add;
        this.updateService = service.update;
        this.getUpdateService = service.getUpdate;
    }
}
</script>

<style scoped>

</style>
