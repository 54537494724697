<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="类型名称">
                <el-input v-model="search.name" size="small"/>
            </x-search-item>
            <x-search-item label="是否固化">
                <x-selector-one v-model="search.status" size="small" dictType="T_YES_OR_NO"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column label="序号" width="50" fixed type="index"/>
            <el-table-column label="类型名称" width="200" prop="name"></el-table-column>
            <el-table-column label="类型编码" width="300" prop="code"></el-table-column>
            <el-table-column label="描述" prop="description"></el-table-column>
            <el-table-column width="120" label="是否固化">
                <x-dict-show slot-scope="{row}" :code="row.status" dictType="T_YES_OR_NO"/>
            </el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button v-if="row.status === 'false'" type="primary" size="mini" round
                               @click="handleUpdate(row.id)">
                        编辑
                    </el-button>
                    <el-popconfirm v-if="row.status === 'false'" title="确定删除这条字典信息吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除
                        </el-button>
                    </el-popconfirm>
                    <el-button v-if="row.status === 'true'" type="primary" size="mini" round
                               @click="handleEditDicts(row.code)">
                        编辑字典项
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <!-- 字典项编辑区域 -->
        <el-dialog title="字典项编辑" :visible.sync="item.show" center append-to-body width="1300px"
                   :close-on-click-modal="false" height="600">
            <el-form ref="itemForm" :model="item.form" :rules="rules" class="x-edit">
                <el-form-item label="类型名称" prop="name" class="w50">
                    <el-input v-model="item.form.name" size="small" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="类型编码" prop="code" class="w50">
                    <el-input v-model="item.form.code" size="small" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="级联父编码" prop="rcode" class="w50">
                    <el-input v-model="item.form.rcode" size="small" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="排序号" prop="sort" class="w50">
                    <el-input-number v-model.number="item.form.sort" size="small" :min=0 :max=1000
                                     :step=1></el-input-number>
                </el-form-item>
                <el-form-item label="字典状态" prop="use" class="w50">
                    <x-radio-group v-model="item.form.use" size="small" dictType="T_USE"/>
                </el-form-item>
                <el-form-item label="操作" class="w50">
                    <el-button type="primary" size="mini" round @click="itemSave" icon="el-icon-document-add">保 存
                    </el-button>
                    <el-button type="success" size="mini" round @click="itemEffect" icon="el-icon-circle-check">固 化
                    </el-button>
                </el-form-item>
            </el-form>
            <el-table :data="item.list" border height="325px">
                <el-table-column label="排序" width="70">
                    <template slot-scope="{ row, $index }">
                        <el-input v-if="row.status === 'true'" v-model="row.sort" size="mini"></el-input>
                        <div v-else>{{ row.sort }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="字典名称"></el-table-column>
                <el-table-column prop="code" label="字典编码" width="150"></el-table-column>
                <el-table-column prop="rcode" label="级联父编码" width="150"></el-table-column>
                <el-table-column label="是否固化" width="120">
                    <x-dict-show slot-scope="{row}" :code="row.status" dictType="T_YES_OR_NO"/>
                </el-table-column>
                <el-table-column label="状态" width="120">
                    <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE"/>
                </el-table-column>
                <el-table-column label="操作" width="250">
                    <template slot-scope="{ row, $index }">
                        <el-popconfirm v-if="row.status === 'false'" title="固化后不可删除，请确认？"
                                       @confirm="itemEffectById(row.id)">
                            <el-button type="success" icon="el-icon-circle-check" size="mini" round slot="reference">固化
                            </el-button>
                        </el-popconfirm>
                        <el-popconfirm v-if="row.status === 'false'" title="确定删除这条字典信息吗？"
                                       @confirm="handleDeleteItme(row.id)">
                            <el-button type="danger" icon="el-icon-delete" size="mini" round slot="reference">删除
                            </el-button>
                        </el-popconfirm>
                        <el-button v-if="row.status === 'true'" type="success" size="mini" round
                                   @click="saveSort(row)">
                            修改排序
                        </el-button>
                        <el-button v-if="row.status === 'true' && row.use === 'false'" type="success" size="mini" round
                                   @click="enable(row.id)">
                            启用
                        </el-button>
                        <el-button v-if="row.status === 'true' && row.use === 'true'" type="success" size="mini" round
                                   @click="disable(row.id)">
                            禁用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import * as service from "@/service/system/dict";
import XTableBase from "@/components/x/XTableBase";
import {ruleBuilder} from '@/util/validate';
import Edit from "@/view/system/dict/DictEdit";
import {deepCopy} from "@/util/objects"

export default {
    name: "DictList",
    mixins: [XTableBase],
    components: {Edit},
    data() {
        return {
            search: {
                name: '',
                status: ''
            },
            item: {
                show: false,
                form: deepCopy(this.defaultItemForm),
                list: []
            }
        }
    },
    beforeCreate() {
        let {required} = ruleBuilder
        this.rules = {
            name: [required()], // 字典名称
            code: [required()], // 字典编码
        }
        this.refreshService = service.list;
        this.service = service;
        this.defaultItemForm = {
            sort: 0,
            description: null,
            code: null,
            pcode: '0',
            rcode: "",
            name: null,
            status: "false",
            use: "false"
        }
    },
    methods: {
        itemSave() {
            this.item.form.status = 'false';
            this.itemDoSave();
        },
        itemEffect() {
            this.item.form.status = 'true';
            this.itemDoSave();
        },
        itemEffectById(id) {
            service.effect(id).then((response) => {
                if (response.msg) {
                    this.$message(response.msg)
                }
                this.handleEditDicts(this.item.form.pcode);
            });
        },
        itemDoSave() {
            this.$refs.itemForm.validate((valid) => {
                if (valid) {
                    service.add(this.item.form).then((response) => {
                        this.$message(response.msg);
                        this.handleEditDicts(this.item.form.pcode);
                    })
                } else {
                    return false;
                }
            });
        },
        handleEditDicts(pcode) {
            service.listByPcode(pcode).then((response) => {
                this.item.list = [];
                if (response.data && response.data.length > 0) {
                    response.data.forEach((obj) => {
                        this.item.list.push(obj);
                    })
                }
                this.item.form = deepCopy(this.defaultItemForm);
                this.item.form.pcode = pcode;
                this.item.show = true;
            })
        },
        handleDeleteItme(id) {
            service.del(id).then((response) => {
                this.$message(response.msg)
                this.handleEditDicts(this.item.form.pcode);
            })
        },
        saveSort(row) {
            service.sort(row.id, row.sort || 0).then(response => {
                //打印提示信息
                this.$message.success(response.msg);
            })
        },
        enable(id) {
            service.use({id, use: "true"}).then((response) => {
                this.$message(response.msg)
                this.handleEditDicts(this.item.form.pcode);
            })
        },
        disable(id) {
            service.use({id, use: "false"}).then((response) => {
                this.$message(response.msg)
                this.handleEditDicts(this.item.form.pcode);
            })
        }
    }
}
</script>

<style scoped>
</style>
